import "./test-mode.css";

export const TestMode = () => {
  return (
    <div className="test-mode">
      <div className="my-container">
        <p className="test-mode-text">Sayt test rejimida ishlamoqda!</p>
      </div>
    </div>
  );
};
